@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
* {
  box-sizing: border-box;
}
body {
  background: whitesmoke;
  min-height:20vh;
  display: flex;
  font-weight: 200;
  font-family:'Poppins', sans-serif !important;
}

h1,h2,h3,h4,h5,h6,label,span,p {
  font-weight: 100 !important;
  font-size: small;
  font-family:'Poppins', sans-serif !important;
}
body, html, .App, #root, .auth-wrapper {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}
 .auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  min-height: 20vh;
  background-color: #002F5B;
}

.auth-inner {
  width: 400px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}


.home-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.home-inner {
  width: 90%;
  height: 50vh;
  margin: auto;
  background: #D9D9D97D;
  
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}

.fixed-bottom{
  margin-top: 0rem;
  
  
}




.payment-inner{
  padding: 6px;
 
   
}
.file-inner{
  height:85vh !important;
 
    overflow-y: scroll;
}
.search-box .Card{
  border-color: red !important;
  height: 10em;
}




.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}
.auth-wrapper h3 {
  text-align: left;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}
.custom-control-label {
  font-weight: 400;
}
.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}
.forgot-password a {
  color: #167bff;
}
.btn {
    background-color: #016726 !important;
    border-color: #016726 !important;
}

.active-pink-4 input[type="text"]:focus:not([readonly]) {
  border: 1px solid #B1B1B1;
  box-shadow: 0 0 0 1px #B1B1B1;
}
.active-pink-3 input[type="text"] {
  border: 1px solid #B1B1B1;
  box-shadow: 0 0 0 1px #B1B1B1;
  width: 100%;
  height: 3rem;
}

.active-pink-3 ::-webkit-input-placeholder {
  text-align: center;
}
.active-pink-3 :-moz-placeholder {
  text-align: center;
}

.icon {
  border-radius: 50%;
  background: #D9D9D9;
  padding: 5px;
  width: 3em;
  height: 3em;
  color: #00000080;
  
}
.icon-text {
  border-radius: 50%;

  padding: 2px;
  width: 2em;
  height: 3em;
  color: #002F5B;
  
}
.text-link{
  padding-left: 13 !important;
   text-decoration: none !important;
   color:#000000 !important;
}
.payment-app{
  text-align: center;
}
.payment-container {
  display: flex;
  flex-direction: row;
  margin: 5% auto;
  width: 635px;
  height: 430px;
  background: white;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.5);
}

.item {
  width: 50%;
  position: relative;
}

.item-image {
  height: 430px;
  width: 100%;
  object-fit: cover;
}

.overlay-effect {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.2;
  background-color: #303030;
  overflow: hidden;
  z-index: 1;
}

.item-details {
  position: absolute;
  bottom: 0;
  margin-bottom: 5px;
  margin-left: 20px;
  color: #84a17d;
  text-align: left;
}

.item-details__title {
  font-size: 22px;
}

.item-details__amount {
  font-weight: bolder;
}

.checkout {
  background: #84a17d; /* fallback for old browsers */

  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 430px;
  width: 50%;
}

.checkout-form {
  padding: 20px 20px;
}

.checkout-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.checkout-field label {
  text-align: left;
  color: #e0eafc;
  font-size: 10px;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.checkout-field input {
  background-color: transparent;
  border: 1px solid #cecece;
  border-radius: 5px;
  color: #e0eafc;
  height: 35px;
}

.paystack-button, .btn-transfer {
  cursor: pointer;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  background-color: green;
  font-weight: bold;
  color: #e0eafc;
  border: none;
  border-radius:0px;
  width: 15%;
  height: 27px;
  margin-top: 40px;
  
}
.user-round{
  border-radius:2rem;
  border-color: black;
}


.modal-dialog {
  height: 90%; /* = 90% of the .modal-backdrop block = %90 of the screen */
}
.modal-content {
  height: 100%; /* = 100% of the .modal-dialog block */
}

.pay input[type="text"]:focus:not([readonly]) {
  border: 1px solid #B1B1B1;
  box-shadow: 0 0 0 1px #B1B1B1;
  
}
.pay input[type="text"] {
  border: 1px solid #B1B1B1;
  border-radius: .5rem;
  box-shadow: 0 0 0 1px #B1B1B1;
  margin-left: .3rem;
  width: 97%;
  height: 4rem;
}

.pay input[type="number"]:focus:not([readonly]) {
  border: 1px solid #B1B1B1;
  box-shadow: 0 0 0 1px #B1B1B1;

  
}
.pay input[type="number"] {
  border: 1px solid #B1B1B1;
  border-radius: .5rem;
  box-shadow: 0 0 0 1px #B1B1B1;
  margin-left: .3rem;
  width: 100%;
  height: 3rem;
}
select{
  width: 100%;
  background-color: white;
  border-radius: .3rem;
  height: 2.5rem;
}
select:focus{
  background-color: white;
}

.profile-btn Button{
  background-color:#002F5B !important;
  color:white !important;

 
}
.logout-btn Button, .edit-form Button{
  background-color:#002F5B !important;
  color:white !important;
  width: 60%;

 
}
.edit-form input{
  width: 100%;
  height:3rem;
  margin-bottom: .5rem;
 
  
  
  
}

.payment-data{
  background-color: #d9d9d9;
  
}




/* Styles for screens larger than 1200px */
@media screen and (min-width: 1200px) {
  .bot p{
    font-size: .7rem;
    margin-bottom: 0;
  }
  .bot{
    padding: 0;
    height:3rem;
  }
  
}

/* Styles for screens between 992px and 1199px */
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .bot p{
    font-size: .7rem;
    margin-bottom: 0;
  }
  .bot{
    padding: 0;
    height:4rem;
  }
  
}

/* Styles for screens between 768px and 991px */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .bot p{
    font-size: .7rem;
    margin-bottom: 0;
    font-weight: 500 !important;
  }
  .bot{
    padding: 0;
    height:3rem;
  }
  .auth-inner {
    width: 60%;
   
  }
  
}

/* Styles for screens smaller than 768px */
@media screen and (max-width: 767px) {
  .bot p{
    font-size: .7rem;
    margin-bottom: 0;
    font-weight: 100 !important;
  }
  .bot{
    padding: 0;
    height:3rem;
  }
  .auth-inner {
    width: 90%;
   
  }
  
}
